import { MutationTree } from 'vuex';

import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IJob, IStudentApplication } from '@/interfaces/job.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_JOB_LIST](state: S, payload: IPaginatedItems<IJob>): void;
	[MutationTypes.SET_JOB](state: S, payload: { jobId: string }): void;
	[MutationTypes.SET_JOB_APPLICANTS](state: S, payload: IPaginatedItems<IStudentApplication>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_JOB_LIST](state: State, payload: IPaginatedItems<IJob>) {
		state.jobList = payload;
	},
	[MutationTypes.SET_JOB](state: State, payload: { jobId: string }) {
		state.job = state.jobList.records.get(payload.jobId);
	},
	[MutationTypes.SET_JOB_APPLICANTS](state: State, payload: IPaginatedItems<IStudentApplication>) {
		state.jobApplicants = payload;
	},
};

import { BursaryService } from '@/services/bursary.service';
import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';
import { RootState } from '../root/state';
import { State } from './state';
import { ActionTypes } from './action-types';
import { RootMutationTypes } from '../root/mutation-types';
import { MutationTypes } from '../bursary/mutation-types';

const bursaryService = Container.get(BursaryService);

export interface Actions {
	[ActionTypes.GET_BURSARY_LIST]({ commit }: ActionContext<State, RootState>): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_BURSARY_LIST]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const bursaryList = await bursaryService.getBursaries();
			commit(MutationTypes.SET_BURSARY_LIST, bursaryList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with getting bursaries. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};

import { MutationTree } from 'vuex';

import { RootMutationTypes } from './mutation-types';
import { RootState } from './state';

export type RootMutations<S = RootState> = {
	[RootMutationTypes.SET_LOADER](state: S, payload: { loading: boolean }): void;
};

export const mutations: MutationTree<RootState> & RootMutations = {
	[RootMutationTypes.SET_LOADER](state: RootState, payload) {
		state.LOADING = payload.loading;
	},
	[RootMutationTypes.SET_ERROR_MSG](state: RootState, payload: string) {
		state.ERROR_MESSAGE = payload;
	},
};

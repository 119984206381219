import { collection, getDocs } from 'firebase/firestore';
import { Service } from 'typedi';
import { mapEmployers } from '@/mappers/employer.mapper';
import { db } from '@/config/firebase.conf';
import { ICompany } from '@/interfaces/employer.interface';

@Service()
export class EmployerService {
	/**
	 * @method getEmployers
	 * @async
	 * @returns {Promise<Array<ICompany>>}
	 */
	async getEmployers(): Promise<Array<ICompany>> {
		const clientSnapshot = await getDocs(collection(db, 'clients'));
		return mapEmployers(clientSnapshot);
	}
}

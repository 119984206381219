<template>
	<v-dialog v-model="dialog" width="auto">
		<div :class="isFilterClicked ? 'table-filter open' : 'table-filter'">
			<div class="close-btn-container">
				<h3>Filter Applicants</h3>
				<v-btn class="close-btn" @click="closeFilter">Close</v-btn>
			</div>
			<v-select
				v-model="selectedTowns"
				:items="uniqueTowns"
				hint="Select towns to filter by"
				label="Select Towns"
				multiple
				persistent-hint
				@update:model-value="emitTowns"
				style="margin-bottom: 2rem"
			>
			</v-select>
			<v-select
				v-model="selectedProvinces"
				:items="uniqueProvinces"
				hint="Select provinces to filter by"
				label="Select Provinces"
				multiple
				persistent-hint
				@update:model-value="emitProvinces"
				style="margin-bottom: 2rem"
			>
			</v-select>
			<v-select
				v-model="selectedYearsOfStudy"
				:items="uniqueYearsOfStudy"
				hint="Select years to filter by"
				label="Select Years"
				multiple
				persistent-hint
				@update:model-value="emitYearsOfStudy"
				style="margin-bottom: 2rem"
			>
			</v-select>
			<v-select
				v-model="selectedDegrees"
				:items="uniqueDegree"
				hint="Select degrees to filter by"
				label="Select Degrees"
				multiple
				persistent-hint
				@update:model-value="emitDegrees"
				style="margin-bottom: 2rem"
			>
			</v-select>
			<v-select
				v-model="selectedStatus"
				:items="availableStatuses"
				hint="Select status to filter by"
				label="Select Status"
				persistent-hint
				@update:modelValue="emitStatus"
				style="margin-bottom: 2rem"
			>
			</v-select>
		</div>
	</v-dialog>
</template>

<script lang="ts" setup>
import { APPLICATION_STATUS } from '@/constants';
import { IStudentApplication } from '@/interfaces/job.interface';
import { IStudentUser } from '@/interfaces/student.interface';
import { defineProps, defineEmits, ref, computed } from 'vue';
import { useStore } from 'vuex';

const selectedTowns = ref([]);
const selectedProvinces = ref([]);
const selectedYearsOfStudy = ref([]);
const selectedDegrees = ref([]);
const selectedStatus = ref(null);
const dialog = ref(true);
const store = useStore();
const props = defineProps({
	isFilterClicked: Boolean,
	applicants: {
		type: Array<IStudentApplication>,
		required: true,
		default: [],
	},
});

const uniqueYearsOfStudy = computed(() =>
	Array.from(new Set(props.applicants.map(applicant => applicant.yearOfStudy))),
);
const uniqueDegree = computed(() => Array.from(new Set(props.applicants.map(applicant => applicant.degree))));
const uniqueTowns = computed(() =>
	Array.from(new Set(props.applicants.map(applicant => getStudentLocation(applicant.alias).town))),
);
const uniqueProvinces = computed(() =>
	Array.from(new Set(props.applicants.map(applicant => getStudentLocation(applicant.alias).province))),
);
const availableStatuses = computed(() =>
	Object.values(APPLICATION_STATUS).filter(status => status !== APPLICATION_STATUS.SHORTLIST),
);

const emit = defineEmits([
	'update:isClicked',
	'update:yearsOfStudy',
	'update:degree',
	'update:town',
	'update:province',
	'update:status',
]);

function closeFilter() {
	emit('update:isClicked', false);
}

function emitYearsOfStudy(yearOfStudy: string[]) {
	emit('update:yearsOfStudy', yearOfStudy);
}
function emitDegrees(degree: string[]) {
	emit('update:degree', degree);
}
function emitTowns(town: string[]) {
	emit('update:town', town);
}
function emitProvinces(province: string[]) {
	emit('update:province', province);
}
function emitStatus(status: APPLICATION_STATUS | null) {
	emit('update:status', status);
}

const getStudentLocation = (studentId: string) => {
	const studentListState = store.state.student.studentList;
	const student: IStudentUser = studentListState.records.get(studentId);

	return student ? student.location : { town: '', province: '' };
};
</script>

import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { Service } from 'typedi';

import { auth } from '@/config/firebase.conf';
import { IUserCredentials } from '@/interfaces/user.interface';
import { checkJoboxEmailDomain } from '@/utils/email.util';

@Service()
export class AuthService {
	/**
	 * @method login
	 * @async
	 * @param {ICredentials} credentials
	 * @returns {Promise<User>}
	 */
	async login(credentials: IUserCredentials): Promise<User> {
		if (!checkJoboxEmailDomain(credentials.email)) {
			throw new Error('Only Jobox users can access the Admin portal.');
		}

		const user = (await signInWithEmailAndPassword(auth, credentials.email, credentials.password)).user;

		if (!user.emailVerified) {
			await signOut(auth);
			throw new Error('Account not verified. Please verify account.');
		}
		return user;
	}

	/**
	 * @method logout
	 * @async
	 * @returns {Promise<void>}
	 */
	async logout(): Promise<void> {
		await signOut(auth);
	}
}

<template>
	<div class="login-container">
		<div class="logo-container">
			<img class="logo" src="../assets/logo.png" />
		</div>

		<v-card class="mx-auto pa-12 pb-8 form-card" elevation="8" max-width="500" rounded="lg" style="margin-left: 20px">
			<div class="text-subtitle-1 text-medium-emphasis">Account</div>

			<v-text-field
				v-model="email"
				type="text"
				density="compact"
				placeholder="Email address"
				prepend-inner-icon="mdi-email-outline"
				variant="outlined"
			></v-text-field>

			<div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
				Password

				<a class="text-caption text-decoration-none text-blue" href="#" rel="noopener noreferrer" target="_blank">
					Forgot login password?
				</a>
			</div>

			<v-text-field
				v-model="password"
				:append-inner-icon="visiblePassword ? 'mdi-eye-off' : 'mdi-eye'"
				:type="visiblePassword ? 'text' : 'password'"
				density="compact"
				placeholder="Enter your password"
				prepend-inner-icon="mdi-lock-outline"
				variant="outlined"
				@click:append-inner="visiblePassword = !visiblePassword"
			></v-text-field>

			<v-btn @click="login" class="primary-btn" append-icon="mdi-location-enter"> Log In </v-btn>

			<p v-if="validationError" style="color: red">{{ validationError }}</p>
		</v-card>
	</div>
</template>

<script lang="ts" setup>
import { VALID_EMAIL_DOMAIN } from '@/constants';
import { ref, ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';
import { RootMutationTypes } from '../store/modules/root/mutation-types';

const email = ref('');
const password = ref('');
const validationError = ref('');
const visiblePassword = ref(false);
const store = useStore();

const setLoading = (value: boolean) => {
	store.dispatch(RootMutationTypes.SET_LOADER, value);
};
const login = async () => {
	if (!email.value.endsWith(VALID_EMAIL_DOMAIN) || password.value.length < 6) {
		validationError.value = 'Incorrect user credentials.';
		return;
	}
	setLoading(true);
	await store.dispatch('LOGIN_USER', { email: email.value, password: password.value });
	setLoading(false);
	validationError.value = store.state.root.ERROR_MESSAGE;
};
</script>

<style scoped>
.login-container {
	padding: 20px;
	border-radius: 10px;
	min-height: 100vh;
}
.logo-container {
	margin-bottom: 20px;
}

.logo {
	margin-bottom: 20px;
	max-width: 100px;
	width: 100%;
	margin-left: 650px;
	margin-top: 20px;
}

.v-btn.primary-btn {
	background: linear-gradient(90deg, #ff6d1f, #ff5724);
	color: white;
	font-size: 16px;
	font-weight: 100;
}
</style>

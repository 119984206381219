import { ActionTypes } from './action-types';
import { RootMutationTypes } from '../root/mutation-types';
import { State } from './state';
import { RootState } from '../root/state'; // Make sure this path is correct
import { ActionTree, ActionContext } from 'vuex';
import Container from 'typedi';
import { SettingsService } from '@/services/settings.service';
import { MutationTypes } from './mutation-types';

const settingsService = Container.get(SettingsService);

export interface Actions {
	[ActionTypes.GET_SETTINGS]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.GET_JOB_CATEGORIES]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.GET_VETTING_SKILLS]({ commit }: ActionContext<State, RootState>): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_SETTINGS]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, true, { root: true });
		try {
			const settings = await settingsService.getSettings();
			commit(MutationTypes.SET_SETTINGS, settings);
		} catch (error: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, error.message || 'Failed to load settings', { root: true });
		} finally {
			commit(RootMutationTypes.SET_LOADER, false, { root: true });
		}
	},
	async [ActionTypes.GET_JOB_CATEGORIES]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, true, { root: true });
		try {
			const jobCategories = await settingsService.getJobCategories();
			commit(MutationTypes.SET_JOB_CATEGORIES, jobCategories);
		} catch (error: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, error.message || 'Failed to load job categories', { root: true });
		} finally {
			commit(RootMutationTypes.SET_LOADER, false, { root: true });
		}
	},
	async [ActionTypes.GET_VETTING_SKILLS]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, true, { root: true });
		try {
			const vettingSkills = await settingsService.getVettingSkills();
			commit(MutationTypes.SET_VETTING_SKILLS, vettingSkills);
		} catch (error: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, error.message || 'Failed to load vetting skills', { root: true });
		} finally {
			commit(RootMutationTypes.SET_LOADER, false, { root: true });
		}
	},
};

<template>
	<div>
		<div v-if="employmentType === 'Once-off Project/Task'">
			<p class="text-center">Let us know what your budget is. Minimum amount is <strong>R400.00</strong></p>
			<div class="budget-field">
				<v-text-field
					:rules="[v => !!v || 'Budget is required']"
					clearable
					label="Please enter a budget"
					v-model="budget"
					variant="underlined"
				></v-text-field>
			</div>
			<div class="justify-end">
				<div>
					<p>Jobox service fee (15%): R{{ serviceFee.toFixed(2) }}</p>
					<p>Admin Fee: R{{ adminFee }}</p>
					<p>Total: R{{ total.toFixed(2) }}</p>
				</div>
			</div>
		</div>
		<div v-else>
			<p class="text-center">
				Let us know what the employee salary is. Minimum amount is <strong>R2000.00</strong> per month.
			</p>
			<div class="budget-field">
				<v-text-field
					:rules="[v => !!v || 'Minimum salary is required']"
					v-model="min"
					clearable
					label="Please enter a minimum salary"
					variant="underlined"
				></v-text-field>
			</div>

			<div class="budget-field">
				<v-text-field
					:rules="[v => !!v || 'Maximum salary is required']"
					v-model="max"
					clearable
					label="Please enter a maximum salary"
					variant="underlined"
				></v-text-field>
			</div>

			<div class="justify-end">
				<p>
					Salary Range: <strong>R{{ min }} - R{{ max }}</strong>
				</p>
			</div>

			<br />
			<br />
			<br />
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watchEffect, defineProps, onUpdated, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const job = computed(() => store.state.job.job);
const props = defineProps({
	employmentType: String,
});

const budget = ref(typeof job.value.budget !== 'object' ? job.value.budget : 400);
const adminFee = ref(100.0);
const jobServiceFee = ref(0);
const jobTotal = ref(0);
const min = ref(typeof job.value.budget === 'object' ? job.value.budget.min : 2000);
const max = ref(typeof job.value.budget === 'object' ? job.value.budget.max : 52000);

// Emits
const emit = defineEmits(['budget', 'adminFee', 'jobServiceFee', 'jobTotal', 'min', 'max', 'thirdStepValidated']);

const serviceFee = computed(() => {
	return Number(budget.value) * 0.15;
});

const total = computed(() => {
	return Number(budget.value) + serviceFee.value + Number(adminFee.value);
});

watchEffect(() => {
	jobServiceFee.value = serviceFee.value.toFixed(2);
	jobTotal.value = total.value.toFixed(2);

	let fieldsValid = false;

	if (props.employmentType === 'Once-off Project/Task') {
		fieldsValid = budget.value !== '' && Number(budget.value) >= 400;
	} else {
		const minValid = min.value !== '' && Number(min.value) >= 2000;
		const maxValid = max.value !== '' && Number(max.value) >= Number(min.value);
		fieldsValid = minValid && maxValid;
	}

	// Emit the overall validation status
	emit('thirdStepValidated', fieldsValid);
});

onUpdated(() => {
	emit('budget', budget.value);
	emit('adminFee', adminFee.value);
	emit('jobServiceFee', jobServiceFee.value);
	emit('jobTotal', jobTotal.value);
	emit('min', min.value);
	emit('max', max.value);
});
</script>

<style lang="scss" scoped>
.budget-field {
	max-width: 800px;
	margin: 5rem auto 5rem auto;
}
</style>

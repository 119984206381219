import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';

export const mutations: MutationTree<State> = {
	[MutationTypes.SET_SETTINGS](state, payload) {
		state.settings = payload;
	},
	[MutationTypes.SET_JOB_CATEGORIES](state, payload) {
		state.jobCategories = payload;
	},
	[MutationTypes.SET_VETTING_SKILLS](state, payload) {
		state.vettingSkills = payload;
	},
};

import { db } from '@/config/firebase.conf';
import { IBursary } from '@/interfaces/bursary.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { DocumentData, QuerySnapshot, where, getDocs, query, collection, limit } from 'firebase/firestore';

export const mapBursaries = async function (
	bursaries: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<IBursary>> {
	const bursaryList = await Promise.all(
		bursaries.docs.map(async (bursarySnap: DocumentData) => {
			const bursary = bursarySnap.data();
			const employerRef = query(collection(db, 'clients'), where('userId', '==', bursary.employerId), limit(1));
			const employerSnap = await getDocs(employerRef);
			const employer = employerSnap.docs[0].data();

			return {
				documentId: bursarySnap.id,
				title: bursary.title,
				description: bursary.description,
				workPlacement: bursary.workPlacement,
				workPlacementInformation: bursary.workPlacementInformation,
				applicationDeadline: bursary.applicationDeadline,
				bursaryRequirements: bursary.bursaryRequirements,
				documentRequirements: bursary.documentRequirements,
				additionalDocuments: bursary.additionalDocuments,
				documentSubmissionInstructions: bursary.documentSubmissionInstructions,
				fundingCover: bursary.fundingCover,
				additionalCoverDetails: bursary.additionalCoverDetails,
				additionalSubmissions: bursary.additionalSubmissions,
				verified: bursary.verified,
				bursaryClosed: bursary.bursaryClosed,
				employerId: bursary.employerId,
				companyName: employer.companyName,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: bursaryList.length,
		totalPages: 1,
		totalItems: bursaryList.length,
		records: new Map(bursaryList.map((obj: IBursary) => [obj.documentId, obj])),
	};
};

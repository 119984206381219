import { collection, DocumentData, getDocs } from 'firebase/firestore';

import { IJoboxUser, IWorkspaceUser } from '@/interfaces/user.interface';
import { db } from '@/config/firebase.conf';

export const mapJoboxUser = async (user: DocumentData): Promise<IJoboxUser> => {
	const workspace = await getDocs(collection(db, 'clients', user.alias, 'invitedClients'));
	const workspaceUsers: Map<string, IWorkspaceUser> = new Map();
	workspace.forEach(doc => {
		workspaceUsers.set(doc.id, doc.data() as IWorkspaceUser);
	});

	return {
		accountCreated: Boolean(user.accountCreated),
		bio: user.bio,
		alias: user.alias,
		email: user.email,
		name: user.name,
		phone: user.phone,
		surname: user.surname,
		profile: user.profile,
		user: user.user,
		userId: user.userId,
		addressLine1: user.addressLine1,
		city: user.city,
		companyCategory: user.companyCategory,
		companyName: user.companyName,
		industry: user.industry,
		postalCode_zipCode: user.postalCode_zipCode,
		vat: user.vat,
		website: user.website,
		workspaceUsers,
	};
};

<template>
	<v-dialog v-model="openSubmission" width="auto">
		<v-card title="Applicant Submission" width="auto">
			<v-card-item>
				<iframe
					:src="checkVideoSubmissionUrl(props.submissionUrl)"
					frameborder="0"
					webkitallowfullscreen
					mozallowfullscreen
					allowfullscreen
				>
				</iframe>
			</v-card-item>
			<v-card-actions>
				<v-btn text="Close" @click="closeSubmission"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps({
	openSubmission: {
		type: Boolean,
		default: false,
	},
	submissionUrl: {
		type: String,
		required: true,
	},
});

const openSubmission = computed(() => props.openSubmission);

const emit = defineEmits(['closeSubmission']);

const closeSubmission = () => {
	emit('closeSubmission', false);
};

const checkVideoSubmissionUrl = (urlSubmission: string) => {
	return urlSubmission.includes('drive.google.com') ? urlSubmission.replace('view', 'preview') : urlSubmission;
};
</script>

<style>
iframe {
	width: 1200px;
	height: 600px;
}
</style>

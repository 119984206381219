import {
	createRouter,
	createWebHistory,
	NavigationGuardNext,
	RouteLocationNormalized,
	RouteRecordRaw,
} from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth';

import * as C from '../constants';
import { JobsRoute } from './jobs.route';
import { StudentsRoute } from './students.route';
import { BursaryRoute } from './bursary.route';
import Login from '@/views/Login.vue';
import { auth } from '@/config/firebase.conf';
import { MutationTypes } from '@/store/modules/auth/mutation-types';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
	{ path: '/', redirect: { name: 'jobs' } },
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	JobsRoute,
	StudentsRoute,
	BursaryRoute,
];

const router = createRouter({
	history: createWebHistory(C.BASE_URL),
	routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
	onAuthStateChanged(auth, async user => {
		if (!user && to.name !== 'login') {
			return next({ name: 'login' });
		}
		if (user) {
			store.commit(MutationTypes.SET_JOBOX_USER, user);
		}
		if (user && to.name === 'login') {
			return next({ name: 'jobs' });
		}

		next();
	});
});

export default router;

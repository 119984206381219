<template>
	<Suspense>
		<div class="table-container">
			<h1 class="text-center">Open Jobs</h1>
			<div class="justify-end">
				<v-btn class="btn-filter" prepend-icon="mdi-filter-outline" variant="outlined" @click="toggleFilterMenu()"
					>Filter</v-btn
				>
			</div>
			<v-card title="Open Jobs" flat>
				<template v-slot:text>
					<v-text-field
						v-model="search"
						label="Search"
						prepend-inner-icon="mdi-magnify"
						variant="outlined"
						hide-details
						single-line
					></v-text-field>
				</template>

				<v-data-table :headers="JOB_TABLE_HEADER" :items="filteredJobs" :search="search" class="elevation-1">
					<template #[`item.actions`]="{ item }">
						<v-btn class="btn-jobox" prepend-icon="mdi-eye-outline" @click="() => viewJob(item)">View</v-btn>
					</template>
				</v-data-table>
			</v-card>
			<JobFilter
				:isFilterClicked="isFilterClicked"
				@update:isClosed="handleFilterClose"
				@update:companyNames="handleCompanyNameChange"
				@update:jobRoles="handleJobRoleChange"
				@update:statuses="handleStatusChange"
				:jobs="jobList || []"
			/>
		</div>
	</Suspense>
</template>

<script lang="ts" setup>
import { ref, computed, Ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { JOB_TABLE_HEADER } from '@/constants';
import JobFilter from '@/components/Filters/JobFilter.vue';
import router from '@/router';
import { IJob } from '@/interfaces/job.interface';
import { MutationTypes } from '@/store/modules/job/mutation-types';

const search: Ref<string> = ref('');
const isFilterClicked: Ref<boolean> = ref(false);
const companyNames: Ref<string[]> = ref([]);
const jobRoles: Ref<string[]> = ref([]);
const statuses: Ref<string[]> = ref([]);
const store = useStore();

const viewJob = (item: IJob) => {
	store.commit(MutationTypes.SET_JOB, { jobId: item.jobId });
	router.push({ name: 'active-job', params: { jobId: item.jobId } });
};
const toggleFilterMenu = () => {
	isFilterClicked.value = !isFilterClicked.value;
};
const handleFilterClose = (filterClose = false) => {
	isFilterClicked.value = filterClose;
};
const handleCompanyNameChange = (companyList: string[]) => {
	companyNames.value = companyList;
};

const handleJobRoleChange = (jobRoleList: string[]) => {
	jobRoles.value = jobRoleList;
};
const handleStatusChange = (jobStatusList: string[]) => {
	statuses.value = jobStatusList;
};

const jobList: ComputedRef<IJob[]> = computed(() => [...store.state.job.jobList.records.values()]);

const filteredJobs: ComputedRef<IJob[]> = computed(() => {
	return (
		jobList.value?.filter(
			(job: IJob) =>
				(companyNames.value.length === 0 || companyNames.value.includes(job.companyName)) &&
				(jobRoles.value.length === 0 || jobRoles.value.includes(job.jobRole)) &&
				(statuses.value.length === 0 || statuses.value.includes(job.status)),
		) || []
	);
});
</script>

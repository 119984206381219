<template>
	<div :class="isFilterClicked ? 'table-filter open' : 'table-filter'">
		<div class="close-btn-container">
			<h3>Filter Students</h3>
			<v-btn class="close-btn" @click="closeFilter">Close</v-btn>
		</div>

		<v-select
			v-model="institutions"
			:items="props.uniqueInstitutions"
			hint="Select institutions to filter by"
			label="Select Institutions"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="qualificationTypes"
			:items="props.uniqueQualificationTypes"
			hint="Select qualification types to filter by"
			label="Select Qualification Types"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="yearOfStudy"
			:items="props.uniqueYearOfStudy"
			hint="Select year of study to filter by"
			label="Select Year of Study"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="degrees"
			:items="props.uniqueDegrees"
			hint="Select degrees to filter by"
			label="Select Degrees"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="skills"
			:items="props.uniqueSkills"
			hint="Select skills to filter by"
			label="Select Skills"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="interestedDepartments"
			:items="props.uniqueInterestedDepartments"
			hint="Select interested departments to filter by"
			label="Select Interested Departments"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="preferredEmployers"
			:items="props.uniquePreferredEmployers"
			hint="Select preferred employers to filter by"
			label="Select Preferred Employers"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="programmes"
			:items="props.uniqueProgrammes"
			hint="Select programmes to filter by"
			label="Select Programmes"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="races"
			:items="props.uniqueRaces"
			hint="Select races to filter by"
			label="Select Races"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="genders"
			:items="props.uniqueGenders"
			hint="Select gender to filter by"
			label="Select Gender"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="driversLicense"
			:items="props.uniqueDriversLicense"
			hint="Select drivers license to filter by"
			label="Select Drivers License"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="vehicle"
			:items="props.uniqueVehicles"
			hint="Select Vehicle to filter by"
			label="Select Vehicle"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="disability"
			:items="props.uniqueDisability"
			hint="Select disability to filter by"
			label="Select Disability"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="citizenships"
			:items="props.uniqueCitizenships"
			hint="Select citizenships to filter by"
			label="Select Citizenships"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>

		<v-select
			v-model="locations"
			:items="props.uniqueLocations"
			hint="Select locations to filter by"
			label="Select Locations"
			multiple
			persistent-hint
			@update:model-value="emitFilters"
		></v-select>
	</div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue';
import { PropType } from 'vue';

const props = defineProps({
	isFilterClicked: Boolean,
	uniqueInstitutions: { type: Array as PropType<string[]>, required: true },
	uniqueQualificationTypes: { type: Array as PropType<string[]>, required: true },
	uniqueYearOfStudy: { type: Array as PropType<string[]>, required: true },
	uniqueDegrees: { type: Array as PropType<string[]>, required: true },
	uniqueSkills: { type: Array as PropType<string[]>, required: true },
	uniqueInterestedDepartments: { type: Array as PropType<string[]>, required: true },
	uniquePreferredEmployers: { type: Array as PropType<string[]>, required: true },
	uniqueProgrammes: { type: Array as PropType<string[]>, required: true },
	uniqueRaces: { type: Array as PropType<string[]>, required: true },
	uniqueGenders: { type: Array as PropType<string[]>, required: true },
	uniqueDriversLicense: { type: Array as PropType<string[]>, required: true },
	uniqueVehicles: { type: Array as PropType<string[]>, required: true },
	uniqueDisability: { type: Array as PropType<string[]>, required: true },
	uniqueCitizenships: { type: Array as PropType<string[]>, required: true },
	uniqueLocations: { type: Array as PropType<string[]>, required: true },
});

const emit = defineEmits<{
	(e: 'update:isClosed', value: boolean): void;
	(
		e: 'filterChanged',
		filters: {
			institutions: string[];
			qualificationTypes: string[];
			yearOfStudy: string[];
			degrees: string[];
			skills: string[];
			interestedDepartments: string[];
			preferredEmployers: string[];
			programmes: string[];
			races: string[];
			genders: string[];
			driversLicense: string[];
			vehicles: string[];
			disability: string[];
			citizenships: string[];
			locations: string[];
		},
	): void;
}>();

const institutions = ref<string[]>([]);
const qualificationTypes = ref<string[]>([]);
const yearOfStudy = ref<string[]>([]);
const degrees = ref<string[]>([]);
const interestedDepartments = ref<string[]>([]);
const skills = ref<string[]>([]);
const preferredEmployers = ref<string[]>([]);
const programmes = ref<string[]>([]);
const races = ref<string[]>([]);
const genders = ref<string[]>([]);
const driversLicense = ref<string[]>([]);
const vehicle = ref<string[]>([]);
const disability = ref<string[]>([]);
const citizenships = ref<string[]>([]);
const locations = ref<string[]>([]);

function closeFilter() {
	emit('update:isClosed', false);
}

function emitFilters() {
	emit('filterChanged', {
		institutions: institutions.value,
		qualificationTypes: qualificationTypes.value,
		yearOfStudy: yearOfStudy.value,
		degrees: degrees.value,
		skills: skills.value,
		interestedDepartments: interestedDepartments.value,
		preferredEmployers: preferredEmployers.value,
		programmes: programmes.value,
		races: races.value,
		genders: genders.value,
		driversLicense: driversLicense.value,
		vehicles: vehicle.value,
		disability: disability.value,
		citizenships: citizenships.value,
		locations: locations.value,
	});
}
</script>

<style lang="scss" scoped>
.v-select {
	margin-bottom: 2rem;
}
</style>

<template>
	<div class="wizard-card">
		<h1 class="text-center wizard-heading">Edit Job</h1>
		<div class="justify-center" style="margin-bottom: 3rem">
			<v-btn @click="saveChanges" class="primary-btn">Save Changes</v-btn>
		</div>
		<v-stepper
			v-model="currentStep"
			:items="['Description', 'Location', 'Salary', 'Additional Submissions', 'Review']"
			class="wizard-stepper"
			color="orange"
		>
			<template v-slot:[`item.1`]>
				<v-card flat>
					<FirstStep
						@jobTitle="addJobTitle"
						@jobIndustry="addJobIndustry"
						@primaryRole="addPrimaryRole"
						@jobDescription="addJobDescription"
						@employmentType="addEmploymentType"
						@minimumEducation="addMinimumEducation"
						@programme="addProgramme"
						@minimumWorkExperience="addMinimumWorkExperience"
						@requiredSkills="addRequiredSkills"
						@firstStepValidated="addFirstStepValidated"
					/>
				</v-card>
			</template>

			<template v-slot:[`item.2`]>
				<v-card title="Locations" flat>
					<SecondStep
						:employmentType="employmentType"
						@selectedType="addSelectedType"
						@location="addLocation"
						@duration="addDuration"
						@workingDays="addWorkingDays"
						@workingHours="addWorkingHours"
						@startDate="addStartDate"
						@secondStepValidated="addSecondStepValidated"
					/>
				</v-card>
			</template>

			<template v-slot:[`item.3`]>
				<v-card title="Salary" flat>
					<ThirdStep
						:employmentType="employmentType"
						@budget="addBudget"
						@adminFee="addAdminFee"
						@jobServiceFee="addJobServiceFee"
						@jobTotal="addJobTotal"
						@min="addMin"
						@max="addMax"
						@thirdStepValidated="addThirdStepValidated"
					/>
				</v-card>
			</template>

			<template v-slot:[`item.4`]>
				<v-card title="Additional Submissions" flat>
					<FourthStep
						@writtenSubmission="addWrittenSubmission"
						@writtenSubmissionDescription="addWrittenSubmissionDescription"
						@videoSubmission="addVideoSubmission"
						@videoSubmissionDescription="addVideoSubmissionDescription"
					/>
				</v-card>
			</template>

			<template v-slot:[`item.5`]>
				<v-card title="Review" flat>
					<FifthStep
						:jobTitle="jobTitle"
						:jobIndustry="jobIndustry"
						:jobDescription="jobDescription"
						:employmentType="employmentType"
						:minimumEducation="minimumEducation"
						:programme="programme"
						:minimumWorkExperience="minimumWorkExperience"
						:requiredSkills="requiredSkills"
						:selectedType="selectedType"
						:location="location"
						:duration="duration"
						:workingDays="workingDays"
						:workingHours="workingHours"
						:startDate="startDate"
						:budget="Number(budget)"
						:adminFee="Number(adminFee)"
						:jobServiceFee="Number(jobServiceFee)"
						:jobTotal="Number(jobTotal)"
						:min="Number(min)"
						:max="Number(max)"
						:writtenSubmission="writtenSubmission"
						:writtenSubmissionDescription="writtenSubmissionDescription"
						:videoSubmission="videoSubmission"
						:videoSubmissionDescription="videoSubmissionDescription"
					/>
				</v-card>
			</template>

			<template v-slot:actions="{ prev, next }">
				<div :class="currentStep > 1 ? 'justify-between' : 'justify-end'" style="padding: 2rem">
					<v-btn v-if="currentStep > 1" @click="prev">Previous</v-btn>
					<v-btn v-if="currentStep !== 5" @click="onNext(next)" class="primary-btn">Next</v-btn>
					<v-btn v-if="currentStep === 5" @click="submit()" class="primary-btn">Submit</v-btn>
				</div>
			</template>
		</v-stepper>
	</div>
</template>

<script setup>
import { computed, ref } from 'vue';
import FirstStep from './formSteps/FirstStep.vue';
import SecondStep from './formSteps/SecondStep.vue';
import ThirdStep from './formSteps/ThirdStep.vue';
import FourthStep from './formSteps/FourthStep.vue';
import FifthStep from './formSteps/FifthStep.vue';
import { useStore } from 'vuex';
import { JOB_LOCATIONS } from '@/constants/job-locations.const';
import router from '@/router';

const store = useStore();
const job = computed(() => store.state.job.job);

const jobTitle = ref('');
const jobIndustry = ref('');
const primaryRole = ref('');
const jobDescription = ref('');
const employmentType = ref(job.value.jobRole);
const minimumEducation = ref('');
const programme = ref('');
const minimumWorkExperience = ref('');
const requiredSkills = ref([]);
const firstStepValidated = ref(false);
const selectedType = ref(null);
const location = ref(null);
const duration = ref(null);
const workingDays = ref(null);
const workingHours = ref(null);
const startDate = ref(null);
const secondStepValidated = ref(false);
const budget = ref(0.0);
const adminFee = ref(0.0);
const jobServiceFee = ref(0.0);
const jobTotal = ref(0.0);
const min = ref(0.0);
const max = ref(0.0);
const thirdStepValidated = ref(false);
const writtenSubmission = ref(null);
const writtenSubmissionDescription = ref(null);
const videoSubmission = ref(null);
const videoSubmissionDescription = ref(null);

const currentStep = ref(1);

function addJobTitle(value) {
	jobTitle.value = value;
}

function addJobIndustry(value) {
	jobIndustry.value = value;
}
function addPrimaryRole(value) {
	primaryRole.value = value;
}

function addJobDescription(value) {
	jobDescription.value = value;
}

function addEmploymentType(value) {
	employmentType.value = value;
}

function addMinimumEducation(value) {
	minimumEducation.value = value;
}

function addProgramme(value) {
	programme.value = value;
}

function addMinimumWorkExperience(value) {
	minimumWorkExperience.value = value;
}

function addRequiredSkills(value) {
	requiredSkills.value = value;
}
function addFirstStepValidated(value) {
	firstStepValidated.value = value;
}

function addSelectedType(value) {
	selectedType.value = value;
}
function addLocation(value) {
	location.value = value;
}
function addDuration(value) {
	duration.value = value;
}
function addWorkingDays(value) {
	workingDays.value = value;
}
function addWorkingHours(value) {
	workingHours.value = value;
}
function addStartDate(value) {
	startDate.value = value;
}
function addSecondStepValidated(value) {
	secondStepValidated.value = value;
}
function addBudget(value) {
	budget.value = value;
}
function addAdminFee(value) {
	adminFee.value = value;
}
function addJobServiceFee(value) {
	jobServiceFee.value = value;
}
function addJobTotal(value) {
	jobTotal.value = value;
}
function addMin(value) {
	min.value = value;
}
function addMax(value) {
	max.value = value;
}
function addThirdStepValidated(value) {
	thirdStepValidated.value = value;
}

function addWrittenSubmission(value) {
	writtenSubmission.value = value;
}
function addWrittenSubmissionDescription(value) {
	writtenSubmissionDescription.value = value;
}
function addVideoSubmission(value) {
	videoSubmission.value = value;
}
function addVideoSubmissionDescription(value) {
	videoSubmissionDescription.value = value;
}
async function saveChanges() {
	try {
		let data = null;
		const jobId = job.value.jobId;

		if (currentStep.value === 1 && firstStepValidated.value) {
			data = {
				name: jobTitle.value,
				industry: jobIndustry.value,
				jobCategory: primaryRole.value,
				description: jobDescription.value,
				jobType: employmentType.value,
				education: minimumEducation.value,
				programme: programme.value,
				experience: minimumWorkExperience.value,
				skills: requiredSkills.value,
				verified: job.value.verified,
			};
		} else if (currentStep.value === 2 && secondStepValidated.value) {
			data = {
				location: selectedType.value,
				address: location.value,
				duration: duration.value,
				workingDays: workingDays.value,
				workingHours: workingHours.value,
				startDate: startDate.value,
				verified: job.value.verified,
			};
		} else if (currentStep.value === 3 && thirdStepValidated.value) {
			data = {
				budget:
					employmentType.value === JOB_LOCATIONS.ONCE_OFF_PROJECT_TASK
						? Number(budget.value)
						: { min: Number(min.value), max: Number(max.value) },
			};
		} else if (currentStep.value === 4) {
			data = {
				additionalSubmissions: {
					writtenSubmission: writtenSubmission.value,
					writtenSubmissionDescription: writtenSubmissionDescription.value,
					videoSubmission: videoSubmission.value,
					videoSubmissionDescription: videoSubmissionDescription.value,
				},
				verified: job.value.verified,
			};
		} else {
			return;
		}
		if (data) {
			await store.dispatch('UPDATE_JOB', { jobId, job: data });
		}
	} catch (error) {
		console.error('An error occurred during the job update process:', error);
	}
}
async function onNext(next) {
	saveChanges();
	next();
}
function submit() {
	router.push({ name: 'active-job', params: { jobId: router.currentRoute.value.params.jobId } });
}
</script>

<style lang="scss" scoped>
.wizard-card {
	padding: 2rem;
	height: calc(100vh - 130px);
	overflow-y: auto;

	.wizard-heading {
		font-weight: 300;
		margin-bottom: 2rem;
	}

	.wizard-stepper {
		max-width: 1800px;
		margin: auto;
	}
}
</style>

import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';

import { IUserCredentials } from '@/interfaces/user.interface';
import { ActionTypes } from './action-types';
import { State } from './state';
import { MutationTypes } from './mutation-types';
import { AuthService } from '@/services/auth.service';
import { RootMutationTypes } from '../root/mutation-types';
import { UserService } from '@/services/user.service';
import { RootState } from '../root/state';
import router from '@/router';

const authService = Container.get(AuthService);
const userService = Container.get(UserService);

export interface Actions {
	[ActionTypes.LOGIN_USER]({ commit }: ActionContext<State, RootState>, payload: IUserCredentials): void;
	[ActionTypes.LOGOUT_USER]({ commit }: ActionContext<State, RootState>): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.LOGIN_USER]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const user = await authService.login(payload);
			await userService.checkIfJoboxUserExists(user.uid);
			router.push({ name: 'jobs' });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with signing in. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.LOGOUT_USER]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await authService.logout();
			commit(MutationTypes.SET_JOBOX_USER, null);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with signing out. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
			router.push({ name: 'login' });
		}
	},
};

export enum JOB_LOCATIONS {
	ON_SITE = 'On-Site',
	HYBRID = 'Hybrid',
	REMOTE = 'Remote',
	ONCE_OFF_PROJECT_TASK = 'Once-off Project/Task',
	INTERNSHIP = 'Internship',
	LEARNERSHIP = 'Learnership',
	PART_TIME = 'Part-time',
	GRADUATE = 'Graduate',
	WORK_INTEGRATED_LEARNING = 'Work Integrated Learning (WIL)',
}

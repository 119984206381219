import { IDropDowns, IJobCategoriesDropDown, IVettingSkills } from '@/interfaces/settings.interface';
import { rootState, RootState } from '../root/state';

export const state = {
	...rootState,
	settings: <IDropDowns[]>[],
	jobCategories: <IJobCategoriesDropDown[]>[],
	vettingSkills: <IVettingSkills[]>[],
};

export type State = typeof state & RootState;

import {
	collection,
	DocumentData,
	documentId,
	getDocs,
	limit,
	orderBy,
	query,
	QuerySnapshot,
	startAfter,
	where,
} from 'firebase/firestore';
import { Service } from 'typedi';

import { db } from '@/config/firebase.conf';
import { IStudentUser } from '@/interfaces/student.interface';
import { mapStudents } from '@/mappers/student.mapper';
import { IPaginatedItems } from '@/interfaces/paginated.interface';

function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
	const chunks: T[][] = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		chunks.push(arr.slice(i, i + chunkSize));
	}
	return chunks;
}

@Service()
export class StudentService {
	/**
	 * @method getStudents
	 * @async
	 * @param {string[]} studentIds
	 * @returns {Promise<IPaginatedItems<IStudentUser>>}
	 */
	async getStudents(studentIds?: string[]): Promise<IPaginatedItems<IStudentUser>> {
		const studentRef = collection(db, 'students');
		const PAGE_SIZE = 100;

		let studentsSnapshot: QuerySnapshot<DocumentData, DocumentData>;

		if (studentIds?.length) {
			const batches = chunkArray(studentIds, 10);
			const batchQueries = batches.map(batch => query(studentRef, where(documentId(), 'in', batch)));

			const querySnapshots = await Promise.all(batchQueries.map(getDocs));

			const allStudentSnaps = querySnapshots.flatMap(snapshot => snapshot.docs);

			studentsSnapshot = { docs: allStudentSnaps } as QuerySnapshot<DocumentData, DocumentData>;
		} else {
			const allStudentSnaps: DocumentData[] = [];

			let studentQuery = query(
				studentRef,
				where('accountCreated', '==', true),
				where('name', '!=', null),
				orderBy('name', 'asc'),
				limit(PAGE_SIZE),
			);
			let hasDocuments = true;

			while (hasDocuments) {
				const snapshot = await getDocs(studentQuery);

				hasDocuments = !snapshot.empty;

				allStudentSnaps.push(...snapshot.docs);
				const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];

				if (snapshot.docs?.length) {
					studentQuery = query(studentRef, startAfter(lastVisibleDoc), limit(PAGE_SIZE));
				} else {
					break;
				}
			}

			studentsSnapshot = { docs: allStudentSnaps } as QuerySnapshot<DocumentData, DocumentData>;
		}

		return mapStudents(studentsSnapshot);
	}
}

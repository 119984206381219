<template>
	<Suspense>
		<div>
			<v-card style="position: relative">
				<v-card-title class="text-center justify-center py-6">
					<h1 class="font-weight-bold text-h2">{{ job.jobName }}</h1>
				</v-card-title>

				<v-card-actions class="justify-center" style="position: relative; bottom: 10px">
					<v-btn @click="jobVerification" :class="job.verified ? 'edit-btn' : 'verify-btn'">{{
						job.verified ? 'Unverify Job' : 'Verify Job'
					}}</v-btn>
					<v-btn class="edit-btn" style="margin: 0 1rem 0 1rem" @click="editJob()">Edit Job</v-btn>

					<v-btn :class="job.jobClosed ? 'edit-btn' : 'close-btn'" @click="jobClosure">{{
						job.jobClosed ? 'Reopen Job' : 'Close Job'
					}}</v-btn>
				</v-card-actions>

				<v-card-item>
					<v-tabs v-model="tab" grow>
						<v-tab v-for="item in tabItems" :key="item" :value="item" :class="{ 'active-tab': tab === item }">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-window v-model="tab">
						<v-window-item v-for="item in tabItems" :key="item" :value="item">
							<div v-if="item === 'Applicants'">
								<v-card flat style="height: calc(100vh - 290px); overflow-y: auto">
									<ApplicationsList />
								</v-card>
							</div>
							<div v-if="item === 'Shortlist'">
								<v-card flat>
									<v-card flat style="height: calc(100vh - 290px); overflow-y: auto">
										<ApplicationsList :status="APPLICATION_STATUS.SHORTLIST" />
									</v-card>
								</v-card>
							</div>
						</v-window-item>
					</v-window>
				</v-card-item>
			</v-card>
		</div>
	</Suspense>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import ApplicationsList from '@/views/Dashboard/jobs/active/applications/ApplicationsList.vue';
import { IJob } from '@/interfaces/job.interface';
import { ActionTypes } from '@/store/modules/job/action-types';
import { MutationTypes } from '@/store/modules/job/mutation-types';
const route = useRoute();
const router = useRouter();
import { APPLICATION_STATUS } from '@/constants';

const tab: Ref<string> = ref('Applicants');
const tabItems: Ref<string[]> = ref(['Applicants', 'Shortlist']);
const store = useStore();

const job: ComputedRef<IJob> = computed(() => store.state.job.job);

const jobVerification = async () => {
	const verified = job.value.verified;
	const proceed = confirm(`Are you sure you want to ${verified ? 'unverify' : 'verify'} this job?`);

	if (proceed) {
		await store.dispatch(ActionTypes.VERIFY_JOB, { jobId: job.value.jobId, verified: !verified });
		store.commit(MutationTypes.SET_JOB, { jobId: job.value.jobId });
		alert(`Job has been ${verified ? 'unverified' : 'verified'}!`);
	}
};

const jobClosure = async () => {
	const jobClosed = job.value.jobClosed;
	const proceed = confirm(`Are you sure you want to ${jobClosed ? 'reopen' : 'close'} this job?`);

	if (proceed) {
		await store.dispatch(ActionTypes.CLOSE_JOB, { jobId: job.value.jobId, closed: !jobClosed });
		store.commit(MutationTypes.SET_JOB, { jobId: job.value.jobId });
		alert(`Job has been ${jobClosed ? 'reopened' : 'closed'}!`);
	}
};

const editJob = () => {
	router.push({ name: 'edit-job', params: { jobId: route.params.jobId } });
};
</script>

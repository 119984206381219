// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportCsv(data: Array<any>, fileName = 'downloadCsv'): void {
	if (!data.length) {
		return;
	}

	const csvContent = [
		Object.keys(data[0]),
		...data.map(item => [Object.values(item).map(value => String(value).replace(/^g/, ''))]),
	]
		.map(e => e.join(','))
		.join('\n');
	const file = new File([csvContent], fileName, { type: 'text/csv;charset=utf-8,' });
	const objUrl = URL.createObjectURL(file);

	window.open(objUrl, '_blank');
	URL.revokeObjectURL(objUrl);
}

import { collection, DocumentData, getDocs, QuerySnapshot, collectionGroup } from 'firebase/firestore';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IExperience, IQualification, IStudentUser, ITestResult } from '@/interfaces/student.interface';
import { db } from '@/config/firebase.conf';

export async function mapStudents(
	students: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<IStudentUser>> {
	const experiencesSnapshot = await getDocs(collectionGroup(db, 'experiences'));
	const qualificationsSnapshot = await getDocs(collectionGroup(db, 'qualifications'));
	const skillsSnapshot = await getDocs(collectionGroup(db, 'skills'));
	const usersSnapshot = await getDocs(collection(db, 'users'));

	const experiencesMap = new Map();
	experiencesSnapshot.docs.forEach(doc => {
		const studentId = doc.ref.parent.parent?.id;
		if (studentId) {
			experiencesMap.set(studentId, [...(experiencesMap.get(studentId) || []), doc.data()]);
		}
	});

	const qualificationsMap = new Map();
	qualificationsSnapshot.docs.forEach(doc => {
		const studentId = doc.ref.parent.parent?.id; // Extract studentId from document path
		if (studentId) {
			qualificationsMap.set(studentId, [...(qualificationsMap.get(studentId) || []), doc.data()]);
		}
	});

	const skillsMap = new Map();
	skillsSnapshot.docs.forEach(doc => {
		const studentId = doc.ref.parent.parent?.id; // Extract studentId from document path
		if (studentId) {
			skillsMap.set(studentId, [...(skillsMap.get(studentId) || []), { id: doc.id, data: doc.data() }]);
		}
	});

	const usersMap = new Map(usersSnapshot.docs.map(doc => [doc.id, doc.data()]));

	const studentList = await Promise.all(
		students.docs.map(async (studentSnap: DocumentData) => {
			const studentId = studentSnap.id;
			const student = studentSnap.data();
			const user = usersMap.get(studentId);

			return {
				accountCreated: Boolean(user?.accountCreated),
				bio: student?.bio,
				alias: user?.alias,
				email: user?.email,
				name: user?.name,
				phone: user?.phone,
				surname: user?.surname,
				profile: student?.profile,
				user: user?.user,
				userId: user?.userId,
				citizenship: student.citizenship,
				cv: student.cv,
				dateOfBirth: student.dateOfBirth,
				disability: student.disability,
				disabilityDescription: student.disabilityDescription,
				extraCurricularActivities: student.extraCurricularActivities,
				gender: student.gender,
				identification: student.identification,
				license: student.license,
				location: student.location,
				preferredCompanies: student.preferredCompanies,
				programme: student.programme,
				race: student.race,
				relocation: student.relocation,
				vehicle: student.vehicle,
				experiences: (experiencesMap.get(studentId) || []).sort((a: IExperience, b: IExperience) =>
					b.startDate.localeCompare(a.startDate),
				),
				qualifications: (qualificationsMap.get(studentId) || []).sort((a: IQualification, b: IQualification) =>
					b.startDate.localeCompare(a.startDate),
				),
				skills: (skillsMap.get(studentId) || [])
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					.map((s: { id: string; data: any }) => {
						const skill = s.data;
						const skillName = s.id;
						const pass = skill.testResults.find((tr: ITestResult) => tr.pass);
						return pass ? skillName.trim() : null;
					})
					.filter((skillName: string) => skillName !== null),
			} as IStudentUser;
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: studentList.length,
		totalPages: 1,
		totalItems: studentList.length,
		records: new Map(studentList.filter(s => s.name && s.surname).map((s: IStudentUser) => [s.alias, s])),
	};
}

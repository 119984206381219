import { GetterTree } from 'vuex';
import { State } from './state';
import { RootState } from '../root/state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IBursary } from '@/interfaces/bursary.interface';

export type Getters = {
	getBursary(state: State): IPaginatedItems<IBursary>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getBursary: state => {
		return state.bursaryList;
	},
};

<template>
	<div :class="isFilterClicked ? 'table-filter open' : 'table-filter'">
		<div class="close-btn-container">
			<h3>Filter Bursaies</h3>
			<v-btn class="close-btn" @click="closeFilter">Close</v-btn>
		</div>
		<v-select
			v-model="companyName"
			:items="uniqueCompanyNames"
			hint="Select companies to filter by"
			label="Select Companies"
			multiple
			persistent-hint
			@update:model-value="emitCompanyNames"
			style="margin-bottom: 2rem"
		>
		</v-select>
		<v-select
			v-model="status"
			:items="uniqueStatus"
			hint="Select status to filter by"
			label="Select Status"
			persistent-hint
			multiple
			@update:model-value="emitStatus"
			style="margin-bottom: 2rem"
		>
		</v-select>
	</div>
</template>

<script lang="ts" setup>
import { defineProps, ref, Ref, computed, defineEmits } from 'vue';
import { IBursary } from '@/interfaces/bursary.interface';

const companyName: Ref<string[]> = ref([]);
const status: Ref<string[]> = ref([]);
const props = defineProps({
	isFilterClicked: Boolean,
	bursaries: {
		type: Array<IBursary>,
		required: true,
		default: [],
	},
});

const uniqueCompanyNames = computed(() => Array.from(new Set(props.bursaries.map(bursary => bursary.companyName))));
const uniqueStatus = ['True', 'False'];

const emit = defineEmits(['update:isClosed', 'update:companyNames', 'update:status']);

function emitCompanyNames(companyName: string) {
	emit('update:companyNames', companyName);
}

function emitStatus(status: string) {
	emit('update:status', status);
}

function closeFilter() {
	emit('update:isClosed', false);
}
</script>

import { ISkills } from '@/interfaces/job.interface';
import { IDropDowns, IJobCategoriesDropDown, IVettingSkills } from '@/interfaces/settings.interface';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

export const mapSettings = async (docSnapshot: DocumentSnapshot<DocumentData>): Promise<IDropDowns> => {
	const settingsData = docSnapshot.data() || {};

	return {
		AccountTypes: settingsData.AccountTypes || [],
		Banks: settingsData.Banks || [],
		Citizenship: settingsData.Citizenship || [],
		ClientSupportSubjects: settingsData.ClientSupportSubjects || [],
		CompanyBenefits: settingsData.CompanyBenefits || [],
		CompanyCategory: settingsData.CompanyCategory || [],
		CompanySizes: settingsData.CompanySizes || [],
		DaysOfTheWeek: settingsData.DaysOfTheWeek || [],
		Deadlines: settingsData.Deadlines || [],
		EducationStatus: settingsData.EducationStatus || [],
		FeedbackSubjects: settingsData.FeedbackSubjects || [],
		Genders: settingsData.Genders || [],
		Graduates: settingsData.Graduates || [],
		Industries: settingsData.Industries || [],
		Institutions: settingsData.Institutions || [],
		JobType: settingsData.JobType || [],
		Languages: settingsData.Languages || [],
		MinimumEducation: settingsData.MinimumEducation || [],
		MinimumExperience: settingsData.MinimumExperience || [],
		Provinces: settingsData.Provinces || [],
		QualificationTypes: settingsData.QualificationTypes || [],
		Races: settingsData.Races || [],
		SkillCategories: settingsData.SkillCategories || [],
		SpecialProgrammes: settingsData.SpecialProgrammes || [],
		StudentSupportSubjects: settingsData.StudentSupportSubjects || [],
		SupportSubjects: settingsData.SupportSubjects || [],
		WorkingHours: settingsData.WorkingHours || [],
		Years: settingsData.Years || [],
		yes_no: settingsData.yes_no || [],
	};
};

export const mapJobCategories = async (
	docSnapshot: DocumentSnapshot<DocumentData>,
): Promise<IJobCategoriesDropDown> => {
	const jobCategories = docSnapshot.data() || {};

	return {
		Administration: jobCategories.Administration || [],
		AdvertisingDesignMedia: jobCategories.AdvertisingDesignMedia || [],
		BankingFinancialServices: jobCategories.BankingFinancialServices || [],
		Education: jobCategories.Education || [],
		Hospitality: jobCategories.Hospitality || [],
		HumanResources: jobCategories.HumanResources || [],
		InformationTechnology: jobCategories.InformationTechnology || [],
		JobCategory: jobCategories.JobCategory || [],
		Legal: jobCategories.Legal || [],
		Retail: jobCategories.Retail || [],
		Sales: jobCategories.Sales || [],
		YES: jobCategories.YES || [],
		job_category: jobCategories.job_category || [],
	};
};

export const mapVettingSkills = async (docSnapshot: DocumentSnapshot<DocumentData>): Promise<IVettingSkills[]> => {
	const vettingSkills = docSnapshot.data() || {};

	return (
		vettingSkills.skills.map((skill: ISkills) => ({
			formId: skill.formId || '',
			skillName: skill.skillName || '',
		})) || []
	);
};

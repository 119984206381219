import { GetterTree } from 'vuex';

import { State } from './state';
import { RootState } from '../root/state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IStudentUser } from '@/interfaces/student.interface';

export type Getters = {
	getStudents(state: State): IPaginatedItems<IStudentUser>;
	getStudent(state: State): IStudentUser | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getStudents: state => {
		return state.studentList;
	},
	getStudent: state => {
		return state.student;
	},
};

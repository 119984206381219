import { MutationTree } from 'vuex';

import { IJoboxUser } from '@/interfaces/user.interface';
import { MutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
	[MutationTypes.SET_JOBOX_USER](state: S, payload: IJoboxUser): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_JOBOX_USER](state: State, payload: IJoboxUser) {
		state.joboxUser = payload;
	},
};

import { GetterTree } from 'vuex';

import { IJoboxUser } from '@/interfaces/user.interface';
import { State } from './state';
import { RootState } from '../root/state';

export type Getters = {
	getJoboxUser(state: State): IJoboxUser;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getJoboxUser: state => {
		return state.joboxUser;
	},
};

import { GetterTree } from 'vuex';
import { State } from './state';
import { RootState } from '../root/state';
import { ICompany } from '@/interfaces/employer.interface';

export type Getters = {
	getEmployers(state: State): Array<ICompany>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getEmployers: state => {
		return state.employerList;
	},
};

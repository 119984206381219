<template>
	<v-dialog v-model="dialog" max-width="700px">
		<v-card>
			<v-toolbar flat class="fixed-bar" color="#e66832">
				<v-toolbar-title>Student Profile</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-row class="mb-3" justify="center">
					<v-col cols="12">
						<v-row class="mb-2" justify="center">
							<v-avatar size="200" variant="outlined">
								<v-img :src="student.profile || DEFAULT_AVATAR" alt="Profile Picture" style="border-radius: 50%" />
							</v-avatar>
						</v-row>
						<v-row class="mb-1" justify="center">
							<span class="name-title" style="font-size: medium">{{ student.name }} {{ student.surname }}</span>
						</v-row>
						<v-row class="mb-2" justify="center">
							<span class="card-sub">{{ student.bio || student.qualifications[0].degree }}</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="auto">
								<v-btn icon color="#e66832" variant="outlined" @click="viewDocument(student.cv?.url)">
									<v-icon>mdi-file-document-outline</v-icon>
								</v-btn>
								&nbsp;
								<v-btn icon color="#e66832" variant="outlined" @click="addToSegment">
									<v-icon>mdi-playlist-plus</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-card class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-account-outline</v-icon> Biological</v-card-title>
					<v-card-text class="pl-2">
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Race:</v-list-item-title>
									<v-list-item-subtitle>{{ student.race }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Gender:</v-list-item-title>
									<v-list-item-subtitle>{{ student.gender }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Date of Birth:</v-list-item-title>
									<v-list-item-subtitle>{{ student.dateOfBirth }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Citizenship:</v-list-item-title>
									<v-list-item-subtitle>{{ student.citizenship }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Disability:</v-list-item-title>
									<v-list-item-subtitle>{{ student.disability }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
				<v-card class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-contacts-outline</v-icon> Contact</v-card-title>
					<v-card-text class="pl-2">
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Email:</v-list-item-title>
									<v-list-item-subtitle>{{ student.email }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Phone:</v-list-item-title>
									<v-list-item-subtitle>{{ student.phone }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
				<v-card v-if="student.location" class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-map-marker-outline</v-icon> Location</v-card-title>
					<v-card-text class="pl-2">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Address:</v-list-item-title>
								<v-list-item-subtitle
									><span>{{ student.location?.streetNumber }} {{ student.location?.streetAddress }}</span
									><br />
									<span>{{ student.location?.town }}</span>
									<br />
									<span>{{ student.location?.province }}</span
									><br />
									<span>{{ student.location?.country }}</span
									><br
								/></v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<br />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Willing to relocate:</v-list-item-title>
								<v-list-item-subtitle>{{ student.relocation }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-card-text>
				</v-card>
				<v-card class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-school-outline</v-icon> Qualifications</v-card-title>
					<v-card-text
						class="pl-2"
						v-for="qualification in student.qualifications"
						:key="qualification.qualificationNum"
					>
						<v-list-item>
							<v-list-item-content>
								<v-row>
									<v-col cols="8">
										<v-list-item-title>{{ qualification.institution }}</v-list-item-title>
										<v-list-item-subtitle
											><span class="card-sub">{{ qualification.degree }}</span
											><br />
											<span class="card-footer">{{ qualification.year }}</span></v-list-item-subtitle
										>
									</v-col>
									<v-col cols="4" class="justify-end">
										<v-btn
											v-if="qualification.academicTranscript?.url"
											class="btn-jobox"
											prepend-icon="mdi-document-outline"
											@click="viewDocument(qualification.academicTranscript.url)"
										>
											View Academic Results
										</v-btn>
									</v-col>
								</v-row>
							</v-list-item-content>
						</v-list-item>
					</v-card-text>
				</v-card>
				<v-card v-if="student.experiences?.length" class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-briefcase-outline</v-icon> Experience</v-card-title>
					<v-card-text class="pl-2" v-for="experience in student.experiences" :key="experience.experienceNum">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>{{ experience.jobTitle }}</v-list-item-title>
								<v-list-item-subtitle
									><span class="card-sub">{{ experience.employer }}</span
									><br />
									<span class="card-footer"
										>{{ experience.startDate }} - {{ experience.endDate || 'Current' }}</span
									></v-list-item-subtitle
								>
							</v-list-item-content>
						</v-list-item>
					</v-card-text>
				</v-card>
				<v-card v-if="student.skills?.length" class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-airballoon-outline</v-icon> Skills</v-card-title>
					<v-card-text class="pl-2">
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<span>{{ student.skills?.join(', ') }}</span>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
				<v-card class="mb-4" elevation="3">
					<v-card-title class="mt-2"><v-icon>mdi-sitemap-outline</v-icon> Employment Preferences</v-card-title>
					<v-card-text class="pl-2">
						<v-list>
							<v-list-item>
								<v-list-item-content v-if="student.preferredCompanies.choices?.length">
									<v-list-item-title>Employers</v-list-item-title>
									<v-list
										><v-list-item v-for="(employer, index) in student.preferredCompanies.choices" :key="index">{{
											employer
										}}</v-list-item></v-list
									>
								</v-list-item-content>
								<br />
								<v-list-item-content v-if="student.preferredCompanies.department?.length">
									<v-list-item-title>Departments</v-list-item-title>
									<v-list
										><v-list-item v-for="(department, index) in student.preferredCompanies.department" :key="index">{{
											department.department ?? department
										}}</v-list-item></v-list
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { ref, ComputedRef, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';

import { IStudentUser } from '@/interfaces/student.interface';
import { DEFAULT_AVATAR } from '@/constants';

const emit = defineEmits(['close']);

const store = useStore();
const dialog = ref(true);

const student: ComputedRef<IStudentUser> = computed(() => store.state.student.student);

const close = () => {
	emit('close');
};

const viewDocument = (fileUrl?: string) => {
	window.open(fileUrl, '_open');
};

const addToSegment = () => {
	// TO-DO: Implement logic to add student to segment
};
</script>
<style lang="scss" scoped>
.primary {
	background-color: #e66832 !important;
}
.name-title {
	font-weight: bold;
	font-size: 20 !important;
}
.card-sub {
	font-size: 14;
}
.card-footer {
	font-weight: lighter;
	font-size: 12;
	color: grey;
}
.fixed-bar {
	position: sticky;
	position: -webkit-sticky; /* for Safari */
	top: 0;
	z-index: 2;
}
</style>

import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';
import { ICompany } from '@/interfaces/employer.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_EMPLOYER_LIST](state: S, payload: Array<ICompany>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_EMPLOYER_LIST](state: State, payload: Array<ICompany>) {
		state.employerList = payload;
	},
};

<template>
	<div :class="isFilterClicked ? 'table-filter open' : 'table-filter'">
		<div class="close-btn-container">
			<h3>Filter Job Openings</h3>
			<v-btn class="close-btn" @click="closeFilter">Close</v-btn>
		</div>
		<v-select
			v-model="companyNames"
			:items="uniqueCompanyNames"
			hint="Select companies to filter by"
			label="Select Companies"
			multiple
			persistent-hint
			@update:model-value="emitCompanyNames"
			style="margin-bottom: 2rem"
		>
		</v-select>
		<v-select
			v-model="jobRoles"
			:items="uniqueJobRoles"
			hint="Select job roles to filter by"
			label="Select Job Roles"
			multiple
			persistent-hint
			@update:model-value="emitJobRoles"
			style="margin-bottom: 2rem"
		>
		</v-select>
		<v-select
			v-model="statuses"
			:items="uniqueStatuses"
			hint="Select statuses to filter by"
			label="Select Statuses"
			multiple
			persistent-hint
			@update:model-value="emitStatuses"
		>
		</v-select>
	</div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, computed, Ref } from 'vue';

const companyNames: Ref<string[]> = ref([]);
const jobRoles: Ref<string[]> = ref([]);
const statuses: Ref<string[]> = ref([]);
const props = defineProps({
	isFilterClicked: Boolean,
	jobs: {
		type: Array,
		required: true,
		default: () => [], // Define the default as a function returning an array
	},
});

const uniqueCompanyNames = computed(() => Array.from(new Set(props.jobs.map(job => job.companyName))));
const uniqueJobRoles = computed(() => Array.from(new Set(props.jobs.map(job => job.jobRole))));
const uniqueStatuses = computed(() => Array.from(new Set(props.jobs.map(job => job.status))));

const emit = defineEmits(['update:isClosed', 'update:companyNames', 'update:jobRoles', 'update:statuses']);

function closeFilter() {
	emit('update:isClosed', false);
}

function emitCompanyNames(companyName: string[]) {
	emit('update:companyNames', companyName);
}
function emitJobRoles(jobRole: string[]) {
	emit('update:jobRoles', jobRole);
}
function emitStatuses(status: string[]) {
	emit('update:statuses', status);
}
</script>

import { MutationTree } from 'vuex';

import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IStudentUser } from '@/interfaces/student.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_STUDENT_LIST](state: S, payload: IPaginatedItems<IStudentUser>): void;
	[MutationTypes.SET_STUDENT](state: S, payload: { studentId: string }): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_STUDENT_LIST](state: State, payload: IPaginatedItems<IStudentUser>) {
		state.studentList = payload;
	},
	[MutationTypes.SET_STUDENT](state: State, payload) {
		state.student = state.studentList.records.get(payload.studentId);
	},
};

import { env } from 'process';
import { USER_TYPE, USER_ROLE } from './user.const';
import { APPLICATION_STATUS } from './job.const';
import { APPLICANT_TABLE_HEADER, JOB_TABLE_HEADER } from './table-headers.const';
import * as JOB from './job.const';

export const VUE_ENV: string = env.VUE_ENV || 'staging';
export const BASE_URL: string = env.VUE_BASE_URL || '';
export const VALID_EMAIL_DOMAIN = '@joinjobox.com';
export const DEFAULT_AVATAR = 'img/card-1.jpg';
export { USER_TYPE, USER_ROLE, APPLICATION_STATUS, JOB_TABLE_HEADER, APPLICANT_TABLE_HEADER, JOB };

import { Service } from 'typedi';
import { db } from '@/config/firebase.conf';
import { doc, getDoc, DocumentSnapshot, DocumentData } from 'firebase/firestore';
import { IDropDowns, IJobCategoriesDropDown, IVettingSkills } from '@/interfaces/settings.interface';
import { mapSettings, mapJobCategories, mapVettingSkills } from '@/mappers/settings.mapper';

@Service()
export class SettingsService {
	/**
	 * Fetches and maps the settings data from Firestore.
	 * @method getSettings
	 * @async
	 * @returns {Promise<IDropDowns>} Mapped settings data.
	 */
	async getSettings(): Promise<IDropDowns> {
		const settingsDocRef = doc(db, 'Settings', 'Drop-down Lists');
		const settingsSnapshot: DocumentSnapshot<DocumentData> = await getDoc(settingsDocRef);
		return await mapSettings(settingsSnapshot);
	}

	/**
	 * Fetches and maps the job categories data from Firestore.
	 * @method getJobCategories
	 * @async
	 * @returns {Promise<IJobCategoriesDropDown>} Mapped job categories data.
	 */
	async getJobCategories(): Promise<IJobCategoriesDropDown> {
		const jobCategoriesDocRef = doc(db, 'Settings', 'Job Category Drop-down Lists');
		const jobCategoriesSnapshot: DocumentSnapshot<DocumentData> = await getDoc(jobCategoriesDocRef);
		return await mapJobCategories(jobCategoriesSnapshot);
	}

	/**
	 * Fetches and maps the vetting skills data from Firestore.
	 * @method getVettingSkills
	 * @async
	 * @returns {Promise<IVettingSkills[]>} Mapped vetting skills data.
	 */
	async getVettingSkills(): Promise<IVettingSkills[]> {
		const vettingSkillsDocRef = doc(db, 'Settings', 'Vetting');
		const vettingSkillsSnapshot: DocumentSnapshot<DocumentData> = await getDoc(vettingSkillsDocRef);
		return await mapVettingSkills(vettingSkillsSnapshot);
	}
}

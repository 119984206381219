import { GetterTree } from 'vuex';

import { State } from './state';
import { RootState } from '../root/state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IJob, IStudentApplication } from '@/interfaces/job.interface';

export type Getters = {
	getJobs(state: State): IPaginatedItems<IJob>;
	getJobApplicants(state: State): IPaginatedItems<IStudentApplication>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getJobs: state => {
		return state.jobList;
	},
	getJobApplicants: state => {
		return state.jobApplicants;
	},
};

export enum APPLICATION_STATUS {
	APPLIED = 'Applied',
	WITHDRAWN = 'Withdrawn',
	INTERVIEW_REQUEST = 'Interview Request Sent',
	OFFER_SENT = 'Offer Sent',
	OFFER_REJECTED = 'Offer Rejected',
	IN_PROGRESS = 'In Progress',
	DISQUALIFIED = 'Disqualified',
	COMPLETED = 'Completed',
	TIMED_OUT = 'Timed Out',
	ACTIVE = 'Active',
	SHORTLIST = 'Shortlist',
	CANCEL = 'Cancel',
	DECLINE = 'Decline',
}

export enum JOB_TYPE {
	ONCE_OFF = 'Once-off Project/Task',
	INTERNSHIP = 'Internship',
	PART_TIME = 'Part-time',
	LEARNERSHIP = 'Learnership',
	GRADUATE = 'Graduate',
}

export enum MIN_EDUCATION {
	NO_PREFERENCE = 'No preference',
	GRADE_10_11 = 'Grade 10 - 11',
	MATRIC = 'Matric',
	TERTIARY_CERT = 'Tertiary certificate',
	DEGREE_OR_HIGHER = 'Degree or higher',
}

export enum MIN_EXPERIENCE {
	NONE = 'None required',
	'0_3' = '0 - 3 months',
	'3_6' = '3 - 6 months',
	'6_12' = '6 - 12 months',
}

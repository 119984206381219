import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { rootState, RootState } from '../root/state';
import { IJob, IStudentApplication } from '@/interfaces/job.interface';

export const state = {
	...rootState,
	jobList: <IPaginatedItems<IJob>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
	job: <IJob | undefined>{},
	jobApplicants: <IPaginatedItems<IStudentApplication>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
};

export type State = typeof state & RootState;

import { doc, updateDoc } from 'firebase/firestore';
import { Service } from 'typedi';

import { db } from '@/config/firebase.conf';
import { APPLICATION_STATUS } from '@/constants';

@Service()
export class ApplicationService {
	/**
	 * @method shortlistApplicant
	 * @async
	 * @param {string} applicationId
	 * @returns {Promise<void>}
	 */
	async updateApplicantStatus(applicationId: string, status: APPLICATION_STATUS): Promise<void> {
		await updateDoc(doc(db, 'applications', applicationId), {
			applicationStatus: status,
			status,
		});
	}
}

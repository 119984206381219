import { collection, getDocs } from 'firebase/firestore';
import { Service } from 'typedi';
import { db } from '@/config/firebase.conf';
import { IBursary } from '@/interfaces/bursary.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { mapBursaries } from '@/mappers/bursary.mapper';

@Service()
export class BursaryService {
	/**
	 * @method getEmployers
	 * @async
	 * @returns {Promise<IPaginatedItems<IBursary>>}
	 */
	async getBursaries(): Promise<IPaginatedItems<IBursary>> {
		const bursarySnapshot = await getDocs(collection(db, 'bursaries'));

		return mapBursaries(bursarySnapshot);
	}
}

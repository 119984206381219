import { Module } from 'vuex';

import { RootState, rootState } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const RootModule: Module<RootState, RootState> = {
	state: rootState,
	mutations,
	actions,
	getters,
};

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// namespaced compatibility

// TO-DO: Move to Secrets
const firebaseConfig = {
	apiKey: 'AIzaSyBZWTf4IMJvaxI-9ZUjv8JTjjr4jUmqm9s',
	authDomain: 'jobox-live-821b5.firebaseapp.com',
	projectId: 'jobox-live-821b5',
	storageBucket: 'jobox-live-821b5.appspot.com',
	messagingSenderId: '415380809893',
	appId: '1:415380809893:web:f8f69fadc18a7956d7454c',
	measurementId: 'G-J1N40D6P9K',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

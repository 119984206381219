import { RouteRecordRaw } from 'vue-router';

import Students from '@/views/Dashboard/Students.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/student/action-types';

export const StudentsRoute: RouteRecordRaw = {
	path: '/students',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'students',
			component: Students,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_STUDENT_LIST);
			},
		},
	],
};

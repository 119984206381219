<template>
	<div class="table-container">
		<div class="justify-end">
			<v-btn class="btn-download" prepend-icon="mdi-download" variant="outlined" @click="downloadCsv()">
				Download CSV
			</v-btn>
			<v-btn class="btn-filter" prepend-icon="mdi-filter-outline" variant="outlined" @click="toggleFilterMenu()"
				>Filter</v-btn
			>
		</div>
		<v-card title="Application List" flat>
			<template v-slot:text>
				<v-text-field
					v-model="search"
					label="Search"
					prepend-inner-icon="mdi-magnify"
					variant="outlined"
					hide-details
					single-line
				></v-text-field>
			</template>

			<v-data-table
				:headers="APPLICANT_TABLE_HEADER"
				:items="filteredApplicants"
				:search="search"
				:loading="loading"
				class="elevation-1"
			>
				<template #[`item.actions`]="{ item }">
					<div style="text-align: right">
						<v-btn
							class="btn-jobox"
							prepend-icon="mdi-account-outline"
							style="margin-right: 1rem; text-align: right"
							@click="viewStudent(item.alias)"
							>View Profile</v-btn
						>
						<v-menu>
							<template #activator="{ props }">
								<v-btn color="transparent" v-bind="props" flat> <v-icon>mdi-dots-vertical</v-icon> </v-btn>
							</template>
							<v-list>
								<v-list-item
									v-if="item.applicationStatus === APPLICATION_STATUS.APPLIED"
									@click="shortlistApplicant(item)"
								>
									<v-list-item-title>Shortlist Candidate</v-list-item-title>
								</v-list-item>
								<v-list-item
									v-if="item.applicationStatus === APPLICATION_STATUS.SHORTLIST"
									@click="removeShortlistedApplicant(item)"
								>
									<v-list-item-title>Remove Candidate</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item.videoSubmissionUrl" @click="viewSubmission(item.videoSubmissionUrl)">
									<v-list-item-title>View Video Submission</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item.writtenSubmissionUrl" @click="viewSubmission(item.writtenSubmissionUrl)">
									<v-list-item-title>View Written Submission</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="checkOptions(item)">
									<v-list-item-title>No Options Available</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
				</template>
			</v-data-table>
		</v-card>
	</div>
	<ApplicationsListFilter
		v-if="isFilterClicked"
		:isFilterClicked="isFilterClicked"
		@update:isClicked="handleFilterClose"
		@update:yearsOfStudy="handleYearsOfStudyChange"
		@update:degree="handleDegreeChange"
		@update:town="handleTownChange"
		@update:province="handleProvinceChange"
		@update:status="handleStatusChange"
		:applicants="applicantList"
	/>
	<ViewSubmission
		:openSubmission="openSubmission"
		:submissionUrl="submissionUrl"
		@closeSubmission="handleCloseSubmission"
	/>
	<StudentModal v-if="isStudentModalOpen" @close="closeStudentModal" />
</template>

<script lang="ts" setup>
import { ref, computed, Ref, ComputedRef, defineProps } from 'vue';
import { useStore } from 'vuex';
import { APPLICANT_TABLE_HEADER, APPLICATION_STATUS } from '@/constants';
import ApplicationsListFilter from '@/components/Filters/ApplicationsListFilter.vue';
import ViewSubmission from '@/components/ViewSubmission.vue';
import { IStudentApplication } from '@/interfaces/job.interface';
import { IStudentUser } from '@/interfaces/student.interface';
import { useRoute } from 'vue-router';
import StudentModal from '@/components/StudentModal.vue';
import { ActionTypes } from '@/store/modules/job/action-types';
import { MutationTypes } from '@/store/modules/student/mutation-types';
import { exportCsv } from '@/utils/file.util';

const search: Ref<string> = ref('');
const isFilterClicked: Ref<boolean> = ref(false);
const isStudentModalOpen: Ref<boolean> = ref(false);
const selectedTowns: Ref<string[]> = ref([]);
const selectedProvinces: Ref<string[]> = ref([]);
const selectedStatus: Ref<APPLICATION_STATUS | null> = ref(null);
const selectedYearsOfStudy: Ref<string[]> = ref([]);
const selectedDegrees: Ref<string[]> = ref([]);
const submissionUrl: Ref<string> = ref('');
const openSubmission: Ref<boolean> = ref(false);
const store = useStore();
const route = useRoute();

const props = defineProps({
	status: {
		type: String,
		default: undefined,
	},
});

const shortlistApplicant = async (applicant: IStudentApplication) => {
	const shortlist = confirm(`Are you sure you want to shortlist ${applicant.name}?`);
	if (shortlist) {
		await store.dispatch(ActionTypes.UPDATE_APPLICANT_STATUS, {
			jobId: route.params.jobId,
			applicationId: applicant.id,
			status: APPLICATION_STATUS.SHORTLIST,
		});
		alert(`${applicant.name} has been shortlisted!`);
	}
};

const removeShortlistedApplicant = async (applicant: IStudentApplication) => {
	const shortlist = confirm(`Are you sure you want to remove ${applicant.name} from shortlist?`);
	if (shortlist) {
		await store.dispatch(ActionTypes.UPDATE_APPLICANT_STATUS, {
			jobId: route.params.jobId,
			applicationId: applicant.id,
			status: APPLICATION_STATUS.APPLIED,
		});
		alert(`${applicant.name} has been remove from shortlist!`);
	}
};

const checkOptions = (item: IStudentApplication) => {
	const noSubmissionUrls = !item.writtenSubmissionUrl && !item.videoSubmissionUrl;
	const isNotShortlistOrApplied =
		item.applicationStatus !== APPLICATION_STATUS.SHORTLIST && item.applicationStatus !== APPLICATION_STATUS.APPLIED;

	return noSubmissionUrls && isNotShortlistOrApplied;
};

const viewStudent = (studentId: string) => {
	store.commit(MutationTypes.SET_STUDENT, { studentId });
	isStudentModalOpen.value = true;
};

const viewSubmission = (submissionsUrl: string) => {
	submissionUrl.value = submissionsUrl;
	openSubmission.value = true;
};

const closeStudentModal = () => {
	isStudentModalOpen.value = false;
};

const toggleFilterMenu = () => {
	isFilterClicked.value = !isFilterClicked.value;
};

const handleFilterClose = (filterClose: boolean) => {
	isFilterClicked.value = filterClose;
};

const handleYearsOfStudyChange = (newYearsOfStudy: string[]) => {
	selectedYearsOfStudy.value = newYearsOfStudy;
};

const handleDegreeChange = (newDegrees: string[]) => {
	selectedDegrees.value = newDegrees;
};

const handleTownChange = (newTowns: string[]) => {
	selectedTowns.value = newTowns;
};

const handleProvinceChange = (newProvinces: string[]) => {
	selectedProvinces.value = newProvinces;
};

const handleStatusChange = (newStatus: APPLICATION_STATUS | null) => {
	selectedStatus.value = newStatus;
};

const handleCloseSubmission = (open: boolean) => {
	openSubmission.value = open;
};

const getApplicants = async () => {
	await store.dispatch(ActionTypes.GET_JOB_APPLICANTS, { jobId: route.params.jobId });
};

getApplicants();

const applicantList: ComputedRef<IStudentApplication[]> = computed(() => [
	...store.state.job.jobApplicants.records.values(),
]);
const loading: ComputedRef<boolean> = computed(() => store.state.root.LOADING);

const filterApplicants = (): IStudentApplication[] => {
	return applicantList.value.filter(applicant => {
		const studentLocation = getStudentLocation(applicant.alias);

		const matchesYearOfStudy =
			selectedYearsOfStudy.value.length === 0 || selectedYearsOfStudy.value.includes(applicant.yearOfStudy);

		const matchesDegree = selectedDegrees.value.length === 0 || selectedDegrees.value.includes(applicant.degree);

		const matchesStatus =
			props.status !== undefined
				? applicant.applicationStatus === props.status
				: selectedStatus.value === null || applicant.applicationStatus === selectedStatus.value;

		const matchesTown = selectedTowns.value.length === 0 || selectedTowns.value.includes(studentLocation.town);

		const matchesProvince =
			selectedProvinces.value.length === 0 || selectedProvinces.value.includes(studentLocation.province);

		return matchesYearOfStudy && matchesDegree && matchesTown && matchesProvince && matchesStatus;
	});
};

const filteredApplicants: ComputedRef<IStudentApplication[]> = computed(() => {
	return filterApplicants();
});

const getStudentLocation = (studentId: string) => {
	const studentListState = store.state.student.studentList;
	const student: IStudentUser = studentListState.records.get(studentId);

	return student ? student.location : { town: '', province: '' };
};
const downloadCsv = () => {
	const headers = ['Name', 'Email', 'Phone Number'];
	const rows = filteredApplicants.value.map(applicant => {
		const student = store.state.student.studentList.records.get(applicant.alias);
		return {
			Name: applicant.name,
			Email: student ? student.email : '',
			PhoneNumber: student ? student.phone : '',
		};
	});

	exportCsv(rows, 'filtered_applicants');
};
</script>
<style lang="scss" scoped>
.table-container {
	padding: 2rem;
}
.btn-download {
	margin-right: 1rem;
	margin-bottom: 15px;
}
</style>

<template>
	<div>
		<h1 class="text-center text-subtitle-1">Let's start with the basic information about the job</h1>
		<div class="field">
			<v-text-field
				clearable
				label="Job Title"
				variant="underlined"
				v-model="jobTitle"
				:rules="[v => !!v || 'Job Title is required']"
			></v-text-field>
		</div>

		<div class="field">
			<v-autocomplete
				v-model="jobIndustry"
				:items="jobIndustries"
				label="Job Industry"
				clearable
				persistent-hint
				variant="underlined"
				:rules="[v => !!v || 'Job Industry is required']"
			></v-autocomplete>
		</div>

		<div class="field" v-if="jobIndustry">
			<v-autocomplete
				v-model="primaryRole"
				:items="primaryRoles[jobIndustry]"
				label="Primary Role/Responsibility"
				clearable
				persistent-hint
				variant="underlined"
				:rules="[v => !!v || 'Primary Role is required']"
			></v-autocomplete>
		</div>

		<div class="field">
			<QuillEditor
				theme="snow"
				:content="jobDescription"
				@update:content="value => (jobDescription = value)"
				contentType="html"
				:style="{ height: '400px' }"
			/>
			<span v-if="!jobDescription" class="error-message">Job Description is required</span>
		</div>

		<div class="field">
			<v-autocomplete
				v-model="employmentType"
				:items="allDropDowns.JobType"
				label="Employment Type"
				persistent-hint
				clearable
				variant="underlined"
				:rules="[v => !!v || 'Employment Type is required']"
			></v-autocomplete>
		</div>

		<div class="field">
			<v-autocomplete
				v-model="minimumEducation"
				:items="allDropDowns.MinimumEducation"
				label="Minimum Level of Education"
				clearable
				persistent-hint
				variant="underlined"
				:rules="[v => !!v || 'Minimum Education is required']"
			></v-autocomplete>
		</div>

		<div class="field">
			<v-autocomplete
				v-model="programme"
				:items="allDropDowns.SpecialProgrammes"
				label="Which programme is this for? (optional)"
				clearable
				persistent-hint
				variant="underlined"
			></v-autocomplete>
		</div>

		<div class="field">
			<v-autocomplete
				v-model="minimumWorkExperience"
				:items="allDropDowns.MinimumExperience"
				label="Minimum Work Experience"
				clearable
				persistent-hint
				variant="underlined"
				:rules="[v => !!v || 'Minimum Work Experience is required']"
			></v-autocomplete>
		</div>

		<div class="field">
			<v-autocomplete
				clearable
				chips
				label="Job Skills"
				:items="vettingSkillsNames"
				item-text="skillName"
				item-value="skillName"
				multiple
				v-model="skillNames"
				:rules="[v => v.length > 0 || 'At least one skill is required']"
			></v-autocomplete>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill';
import { ActionTypes } from '@/store/modules/settings/action-types';

const store = useStore();
const job = computed(() => store.state.job.job);

const jobSkills = job.value.skills || {};
const jobTitle = ref(job.value.jobName || '');
const jobIndustry = ref(jobSkills.industry || '');
const primaryRole = ref(jobSkills.category || '');
const jobDescription = ref(job.value.jobDescription || '');
const employmentType = ref(job.value.jobRole || '');
const minimumEducation = ref(job.value.education || '');
const programme = ref(job.value.programme || '');
const minimumWorkExperience = ref(job.value.experience || '');
const requiredSkills = ref(jobSkills.skills || []);

// Drop Down Refs
const jobIndustries = ref([]);
const primaryRoles = ref({});
const allDropDowns = ref([]);
const vettingSkills = ref([]);
const vettingSkillsNames = computed(() => vettingSkills.value.map(skill => skill.skillName.trim()));

// Define emits
const emit = defineEmits([
	'jobTitle',
	'jobIndustry',
	'primaryRole',
	'jobDescription',
	'employmentType',
	'minimumEducation',
	'programme',
	'minimumWorkExperience',
	'requiredSkills',
	'firstStepValidated',
]);

// Computed property for managing skills
const skillNames = computed({
	get() {
		return Array.isArray(requiredSkills.value) ? requiredSkills.value.map(skill => skill.skillName.trim()) : [];
	},
	set(newSkillNames) {
		requiredSkills.value = newSkillNames.map(skillName => {
			const existingSkill = vettingSkills.value.find(skill => skill.skillName.trim() === skillName.trim());
			if (existingSkill) {
				return { skillName: existingSkill.skillName, formId: existingSkill.formId };
			}
			return { skillName, formId: '' }; // Handle case where formId might not be found
		});
	},
});

// Watch for changes and emit events
watch(
	[
		jobTitle,
		jobIndustry,
		primaryRole,
		jobDescription,
		employmentType,
		minimumEducation,
		programme,
		minimumWorkExperience,
		requiredSkills,
	],
	() => {
		emit('jobTitle', jobTitle.value);
		emit('jobIndustry', jobIndustry.value);
		emit('primaryRole', primaryRole.value);
		emit('jobDescription', jobDescription.value);
		emit('employmentType', employmentType.value);
		emit('minimumEducation', minimumEducation.value);
		emit('programme', programme.value);
		emit('minimumWorkExperience', minimumWorkExperience.value);
		emit('requiredSkills', requiredSkills.value);
		emit('firstStepValidated', validateForm());
	},
	{ immediate: true },
);

// Helper function to normalize keys
function normalizeKey(industryName) {
	return industryName.replace(/,?\s+/g, '').replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
}

// onMounted lifecycle hook for initializing data
onMounted(async () => {
	await store.dispatch(`settings/${ActionTypes.GET_JOB_CATEGORIES}`);
	await store.dispatch(`settings/${ActionTypes.GET_SETTINGS}`);
	await store.dispatch(`settings/${ActionTypes.GET_VETTING_SKILLS}`);

	jobIndustries.value = store.getters['settings/jobCategories'].JobCategory;
	const rolesData = store.getters['settings/jobCategories'];

	jobIndustries.value.forEach(industry => {
		const key = normalizeKey(industry);
		primaryRoles.value[industry] = rolesData[key] || [];
	});

	allDropDowns.value = store.getters['settings/settings'];
	vettingSkills.value = store.getters['settings/vettingSkills'];
});

// Validate form before submitting
function validateForm() {
	return (
		jobTitle.value &&
		jobIndustry.value &&
		primaryRole.value &&
		jobDescription.value &&
		employmentType.value &&
		minimumEducation.value &&
		minimumWorkExperience.value &&
		requiredSkills.value.length > 0
	);
}
</script>

<style lang="scss" scoped>
.field {
	margin: 2rem 0 1rem 0;
}

.error-message {
	color: red;
	font-size: 0.875rem;
	margin-top: 0.5rem;
}
</style>

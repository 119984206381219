import { db } from '@/config/firebase.conf';
import { IJob, IStudentApplication, IUpdateJobDto } from '@/interfaces/job.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { doc, DocumentData, getDoc, QuerySnapshot } from 'firebase/firestore';

export const mapJobs = async function (jobs: QuerySnapshot<DocumentData>): Promise<IPaginatedItems<IJob>> {
	const jobList = await Promise.all(
		jobs.docs.map(async jobSnap => {
			const job = jobSnap.data();
			const microsSnap = await getDoc(doc(db, 'micros', jobSnap.id));
			const micro = microsSnap.data();
			const skillSnap = await getDoc(doc(db, 'skills', jobSnap.id));
			const skillsData = skillSnap.data();

			return {
				additionalSubmissions: {
					submissionLink: micro?.additionalSubmissions?.submissionLink || null,
					videoSubmission: micro?.additionalSubmissions?.videoSubmission || null,
					videoSubmissionDescription: micro?.additionalSubmissions?.videoSubmissionDescription || null,
					writtenSubmission: micro?.additionalSubmissions?.writtenSubmission || null,
					writtenSubmissionDescription: micro?.additionalSubmissions?.writtenSubmissionDescription || null,
				},
				address: micro?.address,
				budget: micro?.budget,
				duration: micro?.duration,
				skills: skillsData,
				employerAlias: job.clientAlias,
				employerId: job.clientId,
				employerName: job.clientName,
				employerProfile: job.clientProfile,
				companyName: job.companyName,
				education: job.education,
				email: job.email,
				experience: job.experience,
				jobClosed: job.jobClosed,
				jobId: job.jobId,
				jobUrl: job.jobUrl,
				location: micro?.location,
				workingDays: micro?.daysOfTheWeek,
				workingHours: micro?.workingHours,
				jobName: job.name,
				jobRole: job.jobType,
				jobDescription: micro?.description,
				status: micro?.status || null,
				phone: job.phone,
				programme: job.programme,
				startDate: job.startDate,
				closeDate: job.closeDate,
				verified: job.verified,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: jobList.length,
		totalPages: 1,
		totalItems: jobList.length,
		records: new Map(jobList.map((obj: IJob) => [obj.jobId, obj])),
	};
};

export const mapStudentApplications = async function (
	applications: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<IStudentApplication>> {
	const applicationList = await Promise.all(
		applications.docs.map(async (appSnap: DocumentData) => {
			const application = appSnap.data();

			return {
				id: appSnap.id,
				alias: application.alias,
				name: application.applicant,
				applicantEmail: application.applicantEmail,
				applicationStatus: application.applicationStatus,
				videoSubmissionUrl: application.videoSubmissionUrl,
				writtenSubmissionUrl: application.writtenSubmissionUrl,
				degree: application.degree[0].degree,
				yearOfStudy: application.degree[0].year,
				status: application.status,
				studentId: application.studentId,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: applicationList.length,
		totalPages: 1,
		totalItems: applicationList.length,
		records: new Map(applicationList.map((obj: IStudentApplication) => [obj.alias, obj])),
	};
};

export const mapUpdateJobToJob = function (job: IUpdateJobDto) {
	const mappedJob = Object.entries({
		name: job.name,
		jobType: job.jobType,
		education: job.education,
		experience: job.experience,
		programme: job.programme,
		startDate: job.startDate,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
	return mappedJob;
};

export const mapUpdateJobToMicro = function (job: IUpdateJobDto) {
	const micro: Partial<IUpdateJobDto> = {
		name: job.name,
		description: job.description,
		budget: job.budget,
		daysOfTheWeek: job.workingDays,
		location: job.location,
		address: job.address,
		duration: job.duration,
	};

	if (job?.additionalSubmissions) {
		micro.additionalSubmissions = {
			submissionLink: job.additionalSubmissions.submissionLink,
			videoSubmission: job.additionalSubmissions.videoSubmission,
			videoSubmissionDescription: job.additionalSubmissions.videoSubmissionDescription,
			writtenSubmission: job.additionalSubmissions.writtenSubmission,
			writtenSubmissionDescription: job.additionalSubmissions.writtenSubmissionDescription,
		};
	}

	return micro;
};

export const mapUpdateJobToSkills = function (job: IUpdateJobDto) {
	const mappedSkills = Object.entries({
		industry: job.industry,
		category: job.jobCategory,
		skills: job.skills,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
	return mappedSkills;
};

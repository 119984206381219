import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { Service } from 'typedi';

import { db } from '@/config/firebase.conf';
import { IJoboxUser } from '@/interfaces/user.interface';
import { mapJoboxUser } from '@/mappers/user.mapper';

@Service()
export class UserService {
	/**
	 * @method
	 * @async
	 * @param {string} userId
	 * @returns {Promise<boolean>}
	 */
	async checkIfJoboxUserExists(userId: string): Promise<IJoboxUser> {
		const q = query(collection(db, 'users'), where('userId', '==', userId));
		const userSnapshot = await getDocs(q);

		if (userSnapshot.empty) {
			throw new Error('User does not exists.');
		}

		const user = userSnapshot.docs[0].data();

		const joboxUserRef = doc(db, 'clients', user.alias);
		const joboxUserSnap = await getDoc(joboxUserRef);

		if (!joboxUserSnap.exists()) {
			throw new Error('Employer does not exists in workspace.');
		}

		const joboxUser = joboxUserSnap.data();

		return mapJoboxUser({ ...user, ...joboxUser });
	}
}

<template>
	<div v-if="loading" class="loader-overlay">
		<div>
			<v-progress-circular indeterminate :size="70" :width="7" color="orange"> </v-progress-circular>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const loading = computed(() => store.state.root.LOADING);
</script>

<style scoped>
.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
</style>

import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IBursary } from '@/interfaces/bursary.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_BURSARY_LIST](state: S, payload: IPaginatedItems<IBursary>): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_BURSARY_LIST](state: State, payload: IPaginatedItems<IBursary>) {
		state.bursaryList = payload;
	},
};

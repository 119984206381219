import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';
import { ActionTypes } from './action-types';
import { State } from './state';
import { RootMutationTypes } from '../root/mutation-types';
import { RootState } from '../root/state';
import { MutationTypes } from './mutation-types';

import { EmployerService } from '@/services/employer.service';

const employerService = Container.get(EmployerService);

export interface Actions {
	[ActionTypes.GET_EMPLOYERS]({ commit }: ActionContext<State, RootState>): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_EMPLOYERS]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const employerList = await employerService.getEmployers();
			commit(MutationTypes.SET_EMPLOYER_LIST, employerList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problem with retrieving employer data. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};

<template>
	<div>
		<h3 class="text-center">Specify the location of work</h3>
		<div class="justify-around">
			<div class="type-container">
				<div
					:class="{ type: true, selected: selectedType === JOB_LOCATIONS.REMOTE }"
					@click="selectType(JOB_LOCATIONS.REMOTE)"
				>
					<v-icon class="job-location-icon">mdi-laptop</v-icon>
				</div>
				<p class="job-location-text">Remote</p>
			</div>
			<div class="type-container">
				<div
					:class="{ type: true, selected: selectedType === JOB_LOCATIONS.ON_SITE }"
					@click="selectType(JOB_LOCATIONS.ON_SITE)"
				>
					<v-icon class="job-location-icon">mdi-office-building</v-icon>
				</div>
				<p class="job-location-text">On-Site</p>
			</div>

			<div class="type-container">
				<div
					:class="{ type: true, selected: selectedType === JOB_LOCATIONS.HYBRID }"
					@click="selectType(JOB_LOCATIONS.HYBRID)"
				>
					<v-icon class="job-location-icon">mdi-home</v-icon>
				</div>
				<p class="job-location-text">Hybrid</p>
			</div>
		</div>

		<input
			type="text"
			v-model="location"
			id="location"
			:class="shouldShowLocationSearch ? 'location-search' : 'hide-search'"
		/>

		<div class="justify-between">
			<div class="location-field">
				<v-autocomplete
					v-if="shouldShowDuration"
					clearable
					label="Duration"
					:items="dropDowns.Deadlines"
					v-model="duration"
					:rules="[v => !!v || 'Duration is required']"
				></v-autocomplete>
				<v-autocomplete
					v-if="shouldShowWorkingDays"
					clearable
					chips
					label="Working Days"
					:items="dropDowns.DaysOfTheWeek"
					multiple
					v-model="workingDays"
					:rules="[v => !!v || 'Working Days is required']"
				></v-autocomplete>
				<v-autocomplete
					v-if="shouldShowWorkingHours"
					clearable
					label="Working Hours"
					:items="dropDowns.WorkingHours"
					v-model="workingHours"
					:rules="[v => !!v || 'Working Hours is required']"
				></v-autocomplete>
			</div>
			<div class="location-field">
				<v-text-field
					:rules="[v => !!v || 'Start Date is required']"
					v-model="startDate"
					v-if="shouldShowStartDate"
					type="date"
					label="Start Date"
				></v-text-field>
			</div>
		</div>
	</div>
</template>

<script setup>
import store from '@/store';
import { ActionTypes } from '@/store/modules/settings/action-types';
import { computed, defineProps, defineEmits, onUpdated, ref, onMounted } from 'vue';
import { JOB_LOCATIONS } from '@/constants/job-locations.const';
/* global google */
const props = defineProps({
	employmentType: String,
});

const job = computed(() => store.state.job.job);
console.log(job);
const selectedType = ref(job.value.location);
const location = ref(job.value.address);
const duration = ref(job.value.duration);
const workingDays = ref(job.value.workingDays);
const workingHours = ref(job.value.workingHours);
const startDate = ref(job.value.startDate);

// Emits
const emit = defineEmits([
	'selectedType',
	'location',
	'duration',
	'workingDays',
	'workingHours',
	'startDate',
	'secondStepValidated',
]);

onMounted(async () => {
	initAutocomplete();
	await store.dispatch(`settings/${ActionTypes.GET_SETTINGS}`);
});

onUpdated(() => {
	emit('selectedType', selectedType.value);
	emit('location', location.value);
	emit('duration', duration.value);
	emit('workingDays', workingDays.value);
	emit('workingHours', workingHours.value);
	emit('startDate', startDate.value);

	const locationValid = !shouldShowLocationSearch.value || location.value.toString() !== '';

	const durationValid = !shouldShowDuration.value || duration.value;
	const workingDaysValid = !shouldShowWorkingDays.value || workingDays.value.length > 0;
	const workingHoursValid = !shouldShowWorkingHours.value || workingHours.value;
	const startDateValid = !!startDate.value;

	const allFieldsValid =
		selectedType.value === JOB_LOCATIONS.REMOTE
			? durationValid && workingDaysValid && workingHoursValid && startDateValid
			: locationValid && durationValid && workingDaysValid && workingHoursValid && startDateValid;

	emit('secondStepValidated', allFieldsValid);
});
const shouldShowLocationSearch = computed(() => {
	return [JOB_LOCATIONS.ON_SITE, JOB_LOCATIONS.HYBRID].includes(selectedType.value);
});

const shouldShowDuration = computed(() => {
	return props.employmentType === JOB_LOCATIONS.ONCE_OFF_PROJECT_TASK;
});

const shouldShowWorkingDays = computed(() => {
	return [
		JOB_LOCATIONS.INTERNSHIP,
		JOB_LOCATIONS.PART_TIME,
		JOB_LOCATIONS.GRADUATE,
		JOB_LOCATIONS.LEARNERSHIP,
	].includes(props.employmentType);
});

const shouldShowWorkingHours = computed(() => {
	return [JOB_LOCATIONS.INTERNSHIP, JOB_LOCATIONS.PART_TIME].includes(props.employmentType);
});

const shouldShowStartDate = computed(() => {
	return true;
});

const selectType = type => {
	selectedType.value = type;
};

function initAutocomplete() {
	const inputElement = document.getElementById('location');
	const autocomplete = new google.maps.places.Autocomplete(inputElement);
	autocomplete.addListener('place_changed', () => {
		const place = autocomplete.getPlace();
		location.value = place.formatted_address || place.name;
	});
}

// Drop Downs
const dropDowns = computed(() => store.getters['settings/settings']);
</script>

<style lang="scss" scoped>
.type-container {
	margin-top: 2rem;
	width: 100px;
	height: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.type {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0 1rem 0 1rem;
	border: 4px solid lightgrey;
	transition: 0.2s ease-in-out;

	&:hover {
		border: 4px solid var(--jobox-primary);
		transition: 0.2s ease-in-out;
		cursor: pointer;
		.job-location-icon {
			color: var(--jobox-primary);
			transition: 0.2s ease-in-out;
		}
	}
}

.type.selected {
	border: 4px solid var(--jobox-primary);
	transition: 0.2s ease-in-out;

	.job-location-icon {
		color: var(--jobox-primary);
		transition: 0.2s ease-in-out;
	}
}
.location-search {
	display: block;
	margin-top: 5rem;
	border-bottom: 1px solid lightgrey;
	width: 100%;
	outline: none;
	padding: 1rem;
}
.job-location-icon {
	font-size: 3rem;
}

.job-location-text {
	margin-top: 1rem;
}

.location-field {
	width: 45%;
	margin-top: 5rem;
}

.hide-search {
	display: none;
}
</style>
